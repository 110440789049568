import React, { memo } from "react";
import PropTypes from "prop-types";

const Tiles = ({ items, className }) => (
  <div className={`columns is-multiline ${className}`}>
    {items.map(item => (
      <div
        className="column is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        key={item.key}
      >
        {item}
      </div>
    ))}
  </div>
);

Tiles.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string
};

Tiles.defaultProps = {
  className: ""
};

export default memo(Tiles);
