import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import Tiles from "../components/tiles";
import TeamCard from "../components/team-card";

const TeamPage = ({ data }) => {
  const items = data.team.edges.map(({ node }) => {
    const { name, personalTitle, image } = node.frontmatter;
    return (
      <TeamCard
        image={image.childImageSharp}
        name={personalTitle ? `${personalTitle} ${name}` : name}
        key={name}
      />
    );
  });

  return (
    <Layout title="Il Team">
      <section className="section">
        <div className="container">
          <h1 className="title is-2">Il Team</h1>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <Tiles items={items} />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    team: allMarkdownRemark(
      filter: { frontmatter: { key: { eq: "team-member" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            name
            personalTitle
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600, maxHeight: 700) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

TeamPage.propTypes = {
  data: PropTypes.shape({ team: PropTypes.object }).isRequired
};

export default TeamPage;
