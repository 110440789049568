import React, { memo } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import styles from "../styles/team-card.module.scss";

const TeamCard = ({ image, name, className }) => {
  return (
    <div className={`card ${className}`}>
      <div className="card-image">
        <div className="image">
          <Img className={styles.image} fluid={image.fluid} alt={name} />
        </div>
      </div>
      <div className="card-content">
        <div className="title is-6">{name}</div>
      </div>
    </div>
  );
};

TeamCard.propTypes = {
  image: PropTypes.shape({ fluid: PropTypes.object }).isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

TeamCard.defaultProps = {
  className: ""
};

export default memo(TeamCard);
